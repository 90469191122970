import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  setContainerBatch,
  setContainerItem,
  setContainerName,
  setContainerNotes,
  setContainerQty,
  setContainerScrap,
  setContainerSupplier,
} from "../../../../actions";
import NumberField from "../../../../components/Fields/NumberField";
import SearchSelectField from "../../../../components/Fields/SearchSelectField";
import TextField from "../../../../components/Fields/TextField";
import TextareaField from "../../../../components/Fields/TextareaField";
import HmiFieldRoute from "../../../../components/HmiFieldRoute";
import onError from "../../../../libs/error-logger";
import intl from "../../../../libs/format-message";
import toast from "../../../../libs/toast";

const CONTAINER_WITHDRAW = loader("../_shared/Withdraw.mutation.graphql");
const BATCH_UPSERT = loader("../_shared/UpsertBatch.mutation.graphql");
const PICKING_ITEM = loader("../_shared/PickingItem.query.graphql");

const Badge = styled.div`
  border-radius: 1.9rem;
  background-color: #eee;
  padding: 0.4rem 1.4rem 0.4rem 0.6rem;
  margin-top: 0.5rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }
`;

function roundWithDecimals(value, decimals = 2) {
  if (!Number.isFinite(value) || !Number.isInteger(decimals)) {
    throw new Error("Invalid input");
  }
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
}

function WithdrawNone() {
  const { sessionId, pickingId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const container = useSelector(({ container }) => container);
  const { hmiConfiguration } = useSelector(({ config }) => config);
  const [stepIndex, setStepIndex] = useState(0);
  const [search, setSearch] = useState("");

  const variables = {
    options: { offset: 0, limit: 25 },
    search,
    pickingId,
    sessionId,
  };
  const { data } = useQuery(PICKING_ITEM, {
    variables,
  });

  const [mutateWithdraw] = useMutation(CONTAINER_WITHDRAW, {
    variables: { sessionId },
  });
  const [mutateUpsertBatch] = useMutation(BATCH_UPSERT, {
    variables: { sessionId },
  });

  async function onSubmit() {
    try {
      const variables = {
        batch: {
          code: container.batch,
          _supplier: container.supplier,
          _item: container.item,
          qty: container.qty,
        },
      };

      const batchResult = await mutateUpsertBatch({ variables });
      const batchId = batchResult.data.batch._id;

      try {
        const variables = {
          pickingId: pickingId,
          qty: container.qty,
          scrap: container.scrap,
          notes: container.notes,
          batchId: batchId,
        };

        await mutateWithdraw({ variables });
        toast({
          title: intl.formatMessage({ id: "app.shared.save_success" }),
        });
        history.go(-2);
      } catch (error) {
        onError(error);
      }
    } catch (error) {
      onError(error);
    }
  }

  const item = data?.session.pickingItem?.item;
  const itemId = item?._id;
  const itemUnit = item?.unit || "";
  const suppliers = data?.suppliers || [];
  const items = data?.itemsPaged?.items || [];

  const subtitle = (
    <Badge>
      <i className="icon-product" /> {item?.code}
    </Badge>
  );

  // Build step sequence accordingly to HMI configuration
  const stepSequence = [];
  if (!hmiConfiguration.SKIP_CONTAINER_NAME) stepSequence.push(0); // NAME
  if (hmiConfiguration.CHECK_CONTAINER_ARTICLE) stepSequence.push(1); // ARTICLE
  stepSequence.push(2); // QUANTITY
  if (!hmiConfiguration.SKIP_CONTAINER_SCRAP) stepSequence.push(3); // SCRAP
  stepSequence.push(4); // BATCH
  if (!hmiConfiguration.SKIP_CONTAINER_SUPPLIER) stepSequence.push(5); // SUPPLIER
  if (!hmiConfiguration.SKIP_CONTAINER_NOTES) stepSequence.push(6); // NOTES

  const incStep = (inc = 1) => {
    const newIndex =
      inc > 0
        ? Math.min(stepIndex + 1, stepSequence.length - 1)
        : Math.max(0, stepIndex - 1);
    if (newIndex === 0) {
      history.goBack();
    } else {
      setStepIndex(newIndex);
    }
  };

  return (
    <>
      {/* NAME */}
      {stepSequence[stepIndex] === 0 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.container_name.title",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          onBack={() => {
            dispatch(setContainerSupplier());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <TextField
              key="name"
              autofocus={true}
              onChange={(value) => {
                dispatch(setContainerName(value));
              }}
            />
          }
        />
      )}
      {/* ARTICLE */}
      {stepSequence[stepIndex] === 1 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.container_batch.batch.article",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : {
                onNext: () => {
                  // Check if container name is not empty and is the same of item name
                  if (
                    container.item &&
                    container.item != itemId &&
                    itemId != ""
                  ) {
                    onError(
                      Error(
                        intl.formatMessage({
                          id: "app.pages.stock.containers.container.actions.load.serialnumbers.error",
                          defaultMessage: "Sorry, wrong articleId selected",
                        })
                      )
                    );
                  } else {
                    incStep();
                  }
                },
              })}
          onBack={() => {
            dispatch(setContainerName());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <SearchSelectField
              key="item"
              autofocus={true}
              placeholder={container.item}
              defaultValue={container.item}
              options={items?.map((i) => {
                return {
                  value: i._id,
                  title: i.code + "::" + i.name,
                  image: i.image ?? "",
                  subtitle: i.description,
                };
              })}
              search={search}
              onSearch={setSearch}
              onChange={(value) => {
                dispatch(setContainerItem(value));
              }}
            />
          }
        />
      )}
      {/* QUANTITY */}
      {stepSequence[stepIndex] === 2 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.produced.title",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          unit={itemUnit}
          onBack={() => {
            dispatch(setContainerQty());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <NumberField
              key="qty"
              autofocus={true}
              placeholder={roundWithDecimals(container.qty)}
              defaultValue={roundWithDecimals(container.qty)}
              onChange={(value) => {
                dispatch(setContainerQty(value));
              }}
            />
          }
        />
      )}
      {/* SCRAP */}
      {stepSequence[stepIndex] === 3 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.scrap.title",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          onBack={() => {
            dispatch(setContainerScrap());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <NumberField
              key="scrap"
              autofocus={true}
              placeholder={container.scrap}
              defaultValue={container.scrap}
              onChange={(value) => {
                dispatch(setContainerScrap(value));
              }}
            />
          }
        />
      )}
      {/* BATCH */}
      {stepSequence[stepIndex] === 4 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.container_batch.title",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          onBack={() => {
            dispatch(setContainerBatch());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <TextField
              key="batch"
              autofocus={true}
              placeholder={container.batch}
              defaultValue={container.batch}
              onChange={(value) => {
                dispatch(setContainerBatch(value));
              }}
            />
          }
        />
      )}
      {/* SUPPLIER */}
      {stepSequence[stepIndex] === 5 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.container_supplier.title",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          onBack={() => {
            dispatch(setContainerSupplier());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <SearchSelectField
              key="supplier"
              placeholder={container.supplier}
              defaultValue={container.supplier}
              onChange={(value) => {
                dispatch(setContainerSupplier(value));
              }}
              options={suppliers.map((s) => {
                return { value: s._id, title: s.name };
              })}
            />
          }
        />
      )}
      {/* NOTES */}
      {stepSequence[stepIndex] === 6 && (
        <HmiFieldRoute
          title="Aggiungi una nota"
          onSubmit={onSubmit}
          onBack={() => {
            dispatch(setContainerNotes());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <TextareaField
              key="notes"
              autofocus={true}
              placeholder={container.notes}
              defaultValue={container.notes}
              onChange={(value) => {
                dispatch(setContainerNotes(value));
              }}
            />
          }
        />
      )}
    </>
  );
}

WithdrawNone.propTypes = {};
export default WithdrawNone;
