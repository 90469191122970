import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import {
  logout,
  resetConfig,
  resetPhase,
  resetTeam,
  setFlow,
  setUser,
} from "../../actions";
import Button from "../../components/Button";
import Clock from "../../components/Clock";
import Header from "../../components/Header";
import Background from "../../components/Layout/Background";
import LoadingIndicator from "../../components/LoadingIndicator";
import CenteredLayout from "../../layouts/CenteredLayout/CenteredLayout.layout";
import { checkErrorMessage } from "../../libs/check_error";
import themes from "../../libs/themes";
import toast from "../../libs/toast";
import nativeChannel from "../../nativeChannel";
import { ReactComponent as IconMagic } from "../../images/magic.svg";

import { loader } from "graphql.macro";
const CREATE_TOKEN = loader("./Welcome.mutation.graphql");
const ACTIVE_SESSIONS = loader("./Welcome.query.graphql");

/**
 * Welcome page
 *
 * @returns {*}
 * @constructor
 */
function Welcome() {
  const theme = themes.welcome;
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { hmiId, hmiConfiguration } = useSelector(({ config }) => config);
  let {
    STATION_ID,
    MULTI_SESSION,
    SCAN_LOGIN,
    STAMP_AFTER_LOGIN,
    WELCOME_CHOOSE_STATION,
    WELCOME_CHOOSE_OPERATOR,
    WELCOME_CHOOSE_ORDER,
    WELCOME_CHOOSE_SCHEDULER,
    SCREENSAVER_URL,
  } = hmiConfiguration;
  const [mutate] = useMutation(CREATE_TOKEN);

  async function onNewTag(tag) {
    const variables = { code: tag };
    try {
      const result = await mutate({ variables });
      const { worker } = result.data;
      dispatch(setUser(worker));
      if (STAMP_AFTER_LOGIN) {
        return history.push("/badge");
      } else {
        return history.push("/worker/dashboard");
      }
    } catch (error) {
      checkErrorMessage(
        intl.formatMessage(
          {
            id: "app.shared.error.invalid_tag",
            defaultMessage: 'Invalid tag: "{tag}"',
          },
          { tag }
        )
      );
    }
  }
  useEffect(() => {
    dispatch(resetConfig());
    dispatch(resetPhase());
    dispatch(resetTeam());

    dispatch(logout());

    if (SCAN_LOGIN) {
      window.onNewTag = onNewTag;
      // If scan login enabled listen nfc events
      nativeChannel.send("nfc_start");
      nativeChannel.on("tag", onNewTag);
      return () => {
        nativeChannel.send("nfc_stop");
        nativeChannel.off("tag", onNewTag);
      };
    }
  }, [dispatch]);

  const variables = { hmiId };
  const { data, loading, error } = useQuery(ACTIVE_SESSIONS, { variables });

  const welcomeButtons = [
    WELCOME_CHOOSE_STATION,
    WELCOME_CHOOSE_OPERATOR,
    WELCOME_CHOOSE_ORDER,
    WELCOME_CHOOSE_SCHEDULER,
  ].filter(Boolean).length;

  const welcomeButtonColWith =
    welcomeButtons > 0 ? Math.floor(12 / welcomeButtons) : 12;

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { sessions } = data;

  function onStationClick(event) {
    event.preventDefault();
    return redirectToPage("STATION");
  }

  function onOperatorClick(event) {
    event.preventDefault();
    return redirectToPage("OPERATOR");
  }

  function onOrderClick(event) {
    event.preventDefault();
    return redirectToPage("ORDER");
  }

  function onSchedulerClick(event) {
    event.preventDefault();
    return redirectToPage("SCHEDULER");
  }

  function redirectToPage(page) {
    if (!MULTI_SESSION && sessions.length > 0) {
      toast({
        title: intl.formatMessage({
          id: "app.pages.welcome.error.not_multisession",
        }),
        icon: "warning",
      });
      return;
    }
    switch (page) {
      case "STATION":
        dispatch(setFlow("STATION"));
        if (STATION_ID) {
          return history.push("/configuration/operator");
        }
        return history.push("/configuration/station");
      case "OPERATOR":
        dispatch(setFlow("OPERATOR"));
        return history.push("/configuration/operator");
      case "ORDER":
        dispatch(setFlow("ORDER"));
        return history.push("/configuration/order");
      case "SCHEDULER":
        dispatch(setFlow("SCHEDULER"));
        return history.push("/configuration/operator");
      default:
        console.error("Unknown page", page);
        return;
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Background
        headHasBorderBottom={false}
        backgroundImage={SCREENSAVER_URL}
        isBodyCentered={true}
        head={
          <Header hasSessionInfo={false} hasClock={false} hasHmiInfo>
            <div className="column has-text-right">
              <Clock className="title is-1 is-double" />
            </div>
          </Header>
        }
        foot={
          <div className="columns">
            {WELCOME_CHOOSE_STATION && (
              <div className={`column is-${welcomeButtonColWith}`}>
                <Button
                  isFullWidth
                  theme={theme.buttons}
                  onClick={onStationClick}
                  data-cy="welcome-start-station"
                >
                  <FormattedMessage
                    id="app.pages.welcome.button.station"
                    defaultMessage="Start"
                  />
                </Button>
              </div>
            )}
            {WELCOME_CHOOSE_OPERATOR && (
              <div className={`column is-${welcomeButtonColWith}`}>
                <Button
                  isFullWidth
                  theme={theme.buttons}
                  onClick={onOperatorClick}
                  data-cy="welcome-start-operator"
                >
                  <FormattedMessage
                    id="app.pages.welcome.button.operator"
                    defaultMessage="Start"
                  />
                </Button>
              </div>
            )}
            {WELCOME_CHOOSE_ORDER && (
              <div className={`column is-${welcomeButtonColWith}`}>
                <Button
                  isFullWidth
                  theme={theme.buttons}
                  onClick={onOrderClick}
                  data-cy="welcome-start-order"
                >
                  <FormattedMessage
                    id="app.pages.welcome.button.order"
                    defaultMessage="Start"
                  />
                </Button>
              </div>
            )}

            {WELCOME_CHOOSE_SCHEDULER && (
              <div className={`column is-${welcomeButtonColWith}`}>
                <Button
                  isFullWidth
                  theme={theme.scheduler}
                  onClick={onSchedulerClick}
                  data-cy="welcome-start-scheduler"
                >
                  <IconMagic className="mr-4" />
                  <FormattedMessage
                    id="app.pages.welcome.button.scheduler"
                    defaultMessage="Scheduler"
                  />
                </Button>
              </div>
            )}
          </div>
        }
      />
    </ThemeProvider>
  );
}

export default Welcome;
