import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { setMachine, setProgram, setStation } from "../../../actions";
import Button from "../../../components/Button";
import { hasFeature } from "../../../components/HasFeature";
import Header from "../../../components/Header";
import Background from "../../../components/Layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import SalesOrder from "../../../components/SalesOrder";
import Title from "../../../components/Title";
import BackImage from "../../../images/arrow-back-gray-icn.svg";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import themes from "../../../libs/themes";
import onWarning from "../../../libs/warning-logger";
import Operation from "./components/Operation";

const OPERATIONS = loader("./ChooseOperation.query.graphql");
const TAKE_PHASE = loader("./Take.mutation.graphql");

function ChooseOperation() {
  const intl = useIntl();
  const theme = themes.default;
  const history = useHistory();
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const [mutate] = useMutation(TAKE_PHASE);

  useEffect(() => {
    dispatch(setProgram(null));
    dispatch(setMachine(null));
  }, [dispatch]);

  const { user, config } = useSelector(({ user, config }) => ({
    user,
    config,
  }));
  const { stationId, hmiConfiguration, flow, features } = config;
  const { OPERATION_SORT: sort, WELCOME_CHOOSE_ORDER } = hmiConfiguration;

  const variables = {
    stationId,
    jobId,
    sort,
    workerId: user?.worker?._id ?? null,
  };
  const { data, loading, error } = useQuery(OPERATIONS, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  let { job } = data;
  let phases = [];

  // Concat phases from job.flatChildren
  for (let i = 0; i < job.flatChildren.length; i++) {
    phases = phases.concat(
      job.flatChildren[i].phases.map((p) => ({
        ...p,
        job: { ...job.flatChildren[i], phases: undefined },
        available: true,
      }))
    );
  }

  // Concat all phases with availablePhases
  if (WELCOME_CHOOSE_ORDER) {
    const allPhases = [...phases, ...job.phases];
    // Add available false if this phases is not available
    const phaseMap = new Map();
    allPhases.forEach((p) => {
      if (!phaseMap.has(p._id)) {
        phaseMap.set(p._id, {
          ...p,
          job: p.job,
          available: false,
        });
      }
    });
    phases.forEach((p) => {
      if (phaseMap.has(p._id)) {
        phaseMap.set(p._id, {
          ...p,
          available: true,
        });
      }
    });
    phases = Array.from(phaseMap.values()).sort((a, b) => a.sort - b.sort);
  }

  async function onClick(phase) {
    const {
      _id,
      _program,
      _machine,
      _station,
      available,
      _worker,
      status,
      activeSessions,
      dependencies,
    } = phase;

    if (!available) {
      if (!hasFeature("MULTI_USER", features) && activeSessions.length > 0) {
        return onWarning(
          new Error(
            intl.formatMessage({
              id: "app.pages.configuration.operations.without.multiuser",
              defaultMessage: "Multi user sessions not enabled",
            })
          )
        );
      }

      if (stationId && _station !== stationId) {
        return onWarning(
          new Error(
            intl.formatMessage({
              id: "app.pages.configuration.operations.assigned.to_another_station",
              defaultMessage:
                "Cannot start this operation, operation assigned to another station",
            })
          )
        );
      }

      if (status === "DONE") {
        return onWarning(
          new Error(
            intl.formatMessage({
              id: "app.pages.configuration.operations.done",
              defaultMessage:
                "Cannot start this operation, operation is completed",
            })
          )
        );
      }

      if (dependencies && dependencies.length > 0) {
        return onWarning(
          new Error(
            intl.formatMessage({
              id: "app.pages.configuration.operations.phase_with_dependency",
              defaultMessage:
                "Cannot start this operation, operation has partial or complete dependency",
            })
          )
        );
      }

      if (_worker && _worker !== user.worker._id) {
        return onWarning(
          new Error(
            intl.formatMessage({
              id: "app.pages.configuration.operations.phase_with_assigned_worker",
              defaultMessage:
                "Cannot start this operation, another worker was assigned",
            })
          )
        );
      } else {
        return onWarning(
          new Error(
            intl.formatMessage({
              id: "app.pages.configuration.operations.phase_with_worker",
              defaultMessage:
                "Cannot start this operation, worker is already working on this operation",
            })
          )
        );
      }
    }

    switch (flow) {
      case "DASHBOARD":
        await mutate({ variables: { phaseId: _id } });
        return history.push("/worker/dashboard");
      case "ORDER":
        if (!user.worker) {
          return history.push("/configuration/operator", { jobId });
        } else {
          dispatch(setStation(_station));
          dispatch(setProgram(_program));
          dispatch(setMachine(_machine));
          const activeSession = activeSessions.find(
            (s) => s.worker._id === user.worker._id
          );
          if (!activeSession) {
            return history.push(
              `/configuration/definition/${jobId}/${_id}/definition`
            );
          } else {
            switch (activeSession.status) {
              case "PAUSE":
                return history.push(
                  `/application/pause/${_id}/${activeSession._id}/${
                    activeSession._step || ""
                  }`
                );
              case "PROBLEM":
                return history.push(
                  `/application/problem/${_id}/${activeSession._id}/${
                    activeSession._step || ""
                  }`
                );
              default:
                return history.push(
                  `/application/step/${_id}/${activeSession._id}/${
                    activeSession._step || ""
                  }`
                );
            }
          }
        }
      case "MANUAL_SESSION":
        dispatch(setStation(_station));
        dispatch(setMachine(_machine));
        return history.push(`/worker/manual-session/time/${_id}`);
      default:
        history.push(`/configuration/definition/${jobId}/${_id}/definition`);
        dispatch(setProgram(_program));
        dispatch(setMachine(_machine));
    }
  }

  function onBack() {
    if (jobId) {
      history.push("/configuration/order");
    } else {
      history.push("/configuration/add-operations");
    }
  }

  if (user?.worker) {
    if (WELCOME_CHOOSE_ORDER) {
      phases = phases.map((p) => {
        if (p.available) {
          if (p._worker && user.worker._id !== p._worker) {
            return {
              ...p,
              available: false,
            };
          }
          if (
            !hasFeature("MULTI_USER", features) &&
            p.activeSessions.length > 0
          ) {
            const activeSession = p.activeSessions.find(
              (s) => s?.worker._id === user.worker._id
            );

            return {
              ...p,
              available: !!activeSession,
            };
          }
          return p;
        } else return p;
      });
      if (flow === "STATION") {
        phases = phases.filter((p) => p._station === stationId);
      }
    } else {
      if (flow === "DASHBOARD") {
        phases = phases.filter(
          (p) =>
            p._tookByWorkers === null ||
            !p._tookByWorkers.includes(user.worker._id)
        );
      }
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasUserInfo hasSessionInfo={false} />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button isFullWidth onClick={onBack}>
                  <img src={BackImage} alt="left-arrow" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            {/* HEAD */}
            <div className="columns">
              <div className="column has-text-centered">
                <h1 className="title is-1 w700">
                  <FormattedMessage
                    id="app.pages.configuration.operations.title"
                    defaultMessage="Choose operation ({value, number} found)"
                    values={{ value: phases.length }}
                  />
                </h1>
                {job && (
                  <p className="subtitle is-2">
                    {job.name}{" "}
                    <small className="fg-secondary">
                      <SalesOrder
                        salesOrderId={job.salesOrderId}
                        meta={job.meta}
                      />
                    </small>
                  </p>
                )}
              </div>
            </div>
            {/* DATA */}
            <div className="columns">
              <div className="column is-10 is-offset-1">
                {phases.map((phase) => (
                  <Operation
                    key={phase._id}
                    phase={phase}
                    job={job}
                    onClick={onClick}
                    user={user}
                  />
                ))}
                {phases.length === 0 && (
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.configuration.operations.no_phase"
                      defaultMessage="There is no phase, contact responsible"
                    />
                  </Title.H2>
                )}
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default ChooseOperation;
