import styled from "styled-components";

export const Badge = styled('div')`
  border-radius: 1.4rem;
  background-color: #d9e0ec;
  padding: 0.4rem 0.7rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;
