import { useQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Swal from "sweetalert2";

import Subtitle from "../../../components/Subtitle";
import { logout, setMachine, setProgram, setStation } from "../../../actions";
import Button from "../../../components/Button";
import SearchField from "../../../components/Fields/SearchField";
import Header from "../../../components/Header";
import Background from "../../../components/Layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import OnScreenKeyboard from "../../../components/OnScreenKeyboard";
import Title from "../../../components/Title";
import BackImage from "../../../images/arrow-back-gray-icn.svg";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import themes from "../../../libs/themes";
import { hasFeature } from "../../../components/HasFeature";
import onWarning from "../../../libs/warning-logger";
import Operation from "./components/Operation";

const JOBS = loader("./ChooseScheduler.query.graphql");
const SAVE_REASON = loader("./ChooseScheduler.mutation.graphql");

function ChooseScheduler() {
  const intl = useIntl();
  const inputEl = useRef(null);
  const theme = themes.default;
  const [search, setSearch] = useState("");
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [sortType, setSortType] = useState("priority");
  const history = useHistory();
  const dispatch = useDispatch();
  const { stationId, hmiConfiguration, flow, features, hmiId } = useSelector(
    ({ config }) => config
  );
  const { user } = useSelector(({ user }) => ({
    user,
  }));

  const { ON_SCREEN_KEYBOARD, PRODUCTION_ORDER_SORT: sort } = hmiConfiguration;

  const variables = {
    stationId,
    sort,
    populate: ["customer", "product"],
  };
  const [saveReason] = useMutation(SAVE_REASON);
  const { data, loading, error } = useQuery(JOBS, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { jobs } = data;

  const phases = jobs.flatMap((job) =>
    job.phases.map((phase) => ({
      ...phase,
      job: { ...job, phases: undefined },
    }))
  );

  phases.sort((a, b) => {
    if (sortType === "priority") {
      return (
        (a.phaseQueued?.priority ?? Infinity) -
        (b.phaseQueued?.priority ?? Infinity)
      );
    } else if (sortType === "job") {
      return a.job.code.localeCompare(b.job.code) || a.sort - b.sort;
    }
    return 0;
  });

  const minPriority = Math.min(
    ...phases.map((phase) => phase.phaseQueued?.priority ?? Infinity)
  );
  const firstMinPriorityIndex = phases.findIndex(
    (phase) => phase.phaseQueued?.priority === minPriority
  );

  if (firstMinPriorityIndex >= 0) {
    phases[firstMinPriorityIndex].available = true;
  } else if (sortType === "job" && phases.length > 0) {
    phases[0].available = true;
  }

  function onBack() {
    if (flow === "SCHEDULER") {
      dispatch(logout());
      history.push("/configuration/operator");
    } else {
      history.push("/");
    }
  }

  function onFocus() {
    if (!ON_SCREEN_KEYBOARD) {
      return;
    }
    setShowKeyboard(true);
    inputEl.current.select();
  }

  const filterPhases = (phase) => {
    if (!search) return true;
    const regex = new RegExp(search.replace(/[^a-z0-9]/gi, "[^a-z0-9]"), "i");
    return (
      regex.test(phase?.sort) ||
      regex.test(phase?.name) ||
      regex.test(phase.job?.name) ||
      regex.test(phase.job?.code) ||
      regex.test(phase.job.customer?.name) ||
      regex.test(phase.job.customer?.code) ||
      regex.test(phase.job.product?.name) ||
      regex.test(phase.job.product?.code) ||
      regex.test(phase.job?.salesOrderId)
    );
  };

  const filteredPhases = phases.filter(filterPhases);

  async function proceedWithPhase(phase) {
    const { _id, _program, _machine, _station, job, activeSessions } = phase;

    dispatch(setStation(_station));
    dispatch(setProgram(_program));
    dispatch(setMachine(_machine));
    const activeSession = activeSessions.find(
      (s) => s.worker._id === user.worker._id
    );
    if (!activeSession) {
      history.push(`/configuration/definition/${job._id}/${_id}/definition`);
    } else {
      switch (activeSession.status) {
        case "PAUSE":
          history.push(
            `/application/pause/${_id}/${activeSession._id}/${
              activeSession._step || ""
            }`
          );
          break;
        case "PROBLEM":
          history.push(
            `/application/problem/${_id}/${activeSession._id}/${
              activeSession._step || ""
            }`
          );
          break;
        default:
          history.push(
            `/application/step/${_id}/${activeSession._id}/${
              activeSession._step || ""
            }`
          );
          break;
      }
    }
  }

  async function saveReasonHandler(phase, option, message = null) {
    const variables = {
      phaseId: phase._id,
      hmiId,
      type: "SCHEDULER_CHOOSE",
      option,
      message,
    };
    try {
      await saveReason({ variables });
    } catch (error) {
      onWarning(error);
    }
  }

  async function showReasonPopup(phase) {
    return new Promise((resolve) => {
      Swal.fire({
        title: intl.formatMessage({
          id: "app.pages.configuration.scheduler.popup.title",
          defaultMessage:
            "Please select the reason why you have selected an order not by Scheduler",
        }),
        text: intl.formatMessage({
          id: "app.pages.configuration.scheduler.popup.subtitle",
          defaultMessage:
            "If you choose another order not by Scheduler more urgent orders may be delayed",
        }),
        cancelButtonText: intl.formatMessage({
          id: "app.pages.configuration.scheduler.popup.cancel",
          defaultMessage: "Return to production plan",
        }),
        icon: "question",
        showCancelButton: true,
        showConfirmButton: false,
        width: "700px",
        buttonsStyling: false,
        customClass: {
          actions: "popup-actions-custom",
          confirmButton: "popup-button",
          cancelButton: "popup-cancel-button",
        },
        footer: `
          <div class="popup-footer" style="display: flex; justify-content: center; gap: 10px;">
            <button class="popup-button" id="reason-responsible">
              ${intl.formatMessage({
                id: "app.pages.configuration.scheduler.popup.reason_responsible",
                defaultMessage: "Order of the responsible",
              })}
            </button>
            <button class="popup-button" id="reason-technical">
              ${intl.formatMessage({
                id: "app.pages.configuration.scheduler.popup.reason_technical",
                defaultMessage: "Technical problem",
              })}
            </button>
            <button class="popup-button" id="reason-resources">
              ${intl.formatMessage({
                id: "app.pages.configuration.scheduler.popup.reason_resource",
                defaultMessage: "No resources available",
              })}
            </button>
            <button class="popup-button" id="reason-other">
              ${intl.formatMessage({
                id: "app.pages.configuration.scheduler.popup.reason_other",
                defaultMessage: "Other reason",
              })}
            </button>
          </div>
        `,
        didOpen: () => {
          document
            .getElementById("reason-responsible")
            .addEventListener("click", () => {
              Swal.close();
              saveReasonHandler(phase, "responsible");
              resolve("responsible");
            });

          document
            .getElementById("reason-technical")
            .addEventListener("click", () => {
              Swal.close();
              saveReasonHandler(phase, "technical");
              resolve("technical");
            });

          document
            .getElementById("reason-resources")
            .addEventListener("click", () => {
              Swal.close();
              saveReasonHandler(phase, "resources");
              resolve("resources");
            });

          document
            .getElementById("reason-other")
            .addEventListener("click", () => {
              Swal.close();
              openOtherReasonPopup().then((reason) => {
                if (reason) {
                  saveReasonHandler(phase, "other", reason);
                  resolve("other");
                } else {
                  resolve(null);
                }
              });
            });
        },
      });
    });
  }

  async function openOtherReasonPopup() {
    const { value: reason } = await Swal.fire({
      showCancelButton: true,
      icon: "question",
      buttonsStyling: false,
      title: intl.formatMessage({
        id: "app.pages.configuration.scheduler.popup.other_reason_title",
        defaultMessage: "Please describe the reason in simple terms",
      }),
      input: "textarea",
      inputPlaceholder: intl.formatMessage({
        id: "app.pages.configuration.scheduler.popup.other_reason_placeholder",
        defaultMessage: "Enter the information here...",
      }),
      confirmButtonText: intl.formatMessage({
        id: "app.pages.configuration.scheduler.popup.other_reason_confirm",
        defaultMessage: "Continue",
      }),
      cancelButtonText: intl.formatMessage({
        id: "app.shared.back",
        defaultMessage: "Back",
      }),
      customClass: {
        actions: "popup-actions-custom",
        confirmButton: "popup-button",
        cancelButton: "popup-cancel-button",
      },
      preConfirm: (value) => {
        if (!value || !value.trim()) {
          Swal.showValidationMessage(
            intl.formatMessage({
              id: "app.pages.configuration.scheduler.popup.validation_required",
              defaultMessage: "Please enter a reason before continuing.",
            })
          );
        }
        return value;
      },
      didOpen: () => {
        const textarea = Swal.getInput();
        const confirmButton = Swal.getConfirmButton();

        confirmButton.disabled = true;

        const handleInput = () => {
          confirmButton.disabled = !textarea.value.trim();
        };

        textarea.addEventListener("input", handleInput);

        Swal.getPopup().addEventListener("hidden.bs.modal", () => {
          textarea.removeEventListener("input", handleInput);
        });
      },
    });
    if (reason) {
      return reason;
    }
    return null;
  }

  async function onClickPhase(phase) {
    const { available, activeSessions, _worker } = phase;

    if (!available) {
      if (!hasFeature("MULTI_USER", features) && activeSessions.length > 0) {
        return onWarning(
          new Error(
            intl.formatMessage({
              id: "app.pages.configuration.operations.without.multiuser",
              defaultMessage: "Multi user sessions not enabled",
            })
          )
        );
      }

      if (_worker && _worker !== user.worker._id) {
        return onWarning(
          new Error(
            intl.formatMessage({
              id: "app.pages.configuration.operations.phase_with_assigned_worker",
              defaultMessage:
                "Cannot start this operation, another worker was assigned",
            })
          )
        );
      }

      const selectedReason = await showReasonPopup(phase);

      if (selectedReason) {
        await proceedWithPhase(phase);
      }
    } else {
      await proceedWithPhase(phase);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Background
        head={
          <Header
            hasUserInfo
            hasSessionInfo={false}
            hasScheduler
            sortByOrdersOrScheduler={sortType === "priority"}
            onToggleSort={(newValue) =>
              setSortType(newValue ? "priority" : "job")
            }
          />
        }
        foot={
          <div className="columns">
            <div className="column is-1">
              <Button isFullWidth onClick={onBack}>
                <img src={BackImage} alt="arrow-left" />
              </Button>
            </div>
          </div>
        }
      >
        <div className="columns">
          <div className="column is-full has-text-centered">
            <Title.H2>
              <FormattedMessage
                id="app.pages.configuration.scheduler.title"
                defaultMessage="Plan of production by Scheduler"
              />
            </Title.H2>
            <Subtitle.S4 theme={themes.secondary}>
              <FormattedMessage
                id="app.pages.configuration.scheduler.subtitle"
                defaultMessage="({value, number} operations, {orderVal, number} orders)"
                values={{ value: phases.length, orderVal: jobs.length }}
              />
            </Subtitle.S4>
          </div>
        </div>
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <SearchField
              defaultValue={search}
              onChange={(value) => setSearch(value)}
              onFocus={onFocus}
              placeholder={intl.formatMessage({
                id: "app.pages.configuration.orders.search.placeholder",
                defaultMessage: "Search order, product or customer...",
              })}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column is-10 is-offset-1">
            {filteredPhases.map((phase) => (
              <Operation
                key={phase._id}
                phase={phase}
                onClick={() => onClickPhase(phase)}
                user={user}
              />
            ))}
            {filteredPhases.length === 0 && (
              <Title.H2>
                <FormattedMessage
                  id="app.pages.configuration.orders.no_order"
                  defaultMessage="There is no job, contact responsible"
                />
              </Title.H2>
            )}
          </div>
        </div>
        {showKeyboard && <OnScreenKeyboard inputNode={inputEl.current} />}
      </Background>
    </ThemeProvider>
  );
}

export default ChooseScheduler;
