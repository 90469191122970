import { Switch, Label, Input } from './styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const ToggleSwitch = ({ off, on, value = false, onChange }) => {
  const [checked, setChecked] = useState(value)

  const handleChange = (e) => {
    const newChecked = e.target.checked;
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  }

  return (
    <Label>
      <span>{off}</span>
      <Input
        checked={checked}
        type="checkbox"
        onChange={handleChange}
      />
      <Switch />
      <span>{on}</span>
    </Label>
  );
}

ToggleSwitch.prototype = {
  off: PropTypes.string,
  on: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func
}

export default ToggleSwitch
