import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

import themes, { statuses } from "../../../../libs/themes";
import ListElement from "../../../../components/ListElement";
import CircleElem from "../../../../components/CircleElem";
import NumberFormatter from "../../../../libs/format-number";
import Unit from "../../../../components/Unit";
import { Badge } from "./styles";

function Operation({ phase, onClick, user }) {
  const activeSession =
    phase.activeSessions.length > 0 &&
    user.worker &&
    phase.activeSessions.find((s) => s?.worker._id === user.worker._id);

  return (
    <ListElement
      onClick={() => onClick(phase)}
      theme={
        phase.available
          ? themes.default.listElement
          : themes.welcome.listElement
      }
      data-cy="configuration-operations-operation"
      data-cy-id={phase._id}
      icon={
        phase?.product?.image
          ? phase?.product?.image
          : phase.job?.product?.image
      }
    >
      <div className="columns is-vcentered is-fullwidth is-marginless">
        <div className={"column"}>
          <p className="subtitle is-6">
            <CircleElem
              theme={
                statuses[activeSession ? activeSession.status : phase?.status]
                  ?.listElement ?? themes.default.listElement
              }
            >
              <FormattedMessage
                id={
                  "app.components.phase.status." +
                  (activeSession
                    ? activeSession.status
                    : phase?.status ?? "NEW")
                }
                defaultMessage=""
              />
            </CircleElem>
            {phase.job && (
              <Badge>
                <i className="icon-sb-job-board-icn" />
                {phase.job.name}
              </Badge>
            )}
            {phase.job && (
              <Badge>
                <i className="icon-st-users-icn" />
                {phase.job.customer?.name}
              </Badge>
            )}
            <Badge>
              <i className="icon-product" />
              {phase?.product?.code}
              {phase.hasSerialnumbers && <i className="icon-star-empty" />}
            </Badge>
            {phase.station && (
              <Badge>
                <i className="icon-st-areas-icn" />
                {phase.station.name}
              </Badge>
            )}
            {phase.job?.salesOrderId && (
              <Badge>
                <i className="icon-doc-text" />
                {phase.job.salesOrderId}
              </Badge>
            )}
            {phase.isExternal && (
              <Badge>
                <i className="icon-giphy-external" />
              </Badge>
            )}
          </p>
          <p className={`title is-3 ${!phase.available && "has-text-grey"}`}>
            {phase.sort} :: {phase.name}
          </p>
        </div>
        <div className="column is-narrow">
          <h4 className={`title is-4 ${!phase.available && "has-text-grey"}`}>
            <NumberFormatter value={phase.produced} />
            {" / "}
            <NumberFormatter value={phase.qty} />
            <Unit unit={phase.unit} />
          </h4>
        </div>
      </div>
    </ListElement>
  );
}

Operation.propTypes = {
  onClick: PropTypes.func.isRequired,
  user: PropTypes.shape({
    token: PropTypes.string.isRequired,
    worker: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      __typename: PropTypes.string.isRequired,
    }),
  }).isRequired,
  phase: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    sort: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    produced: PropTypes.number,
    percentage: PropTypes.number,
    available: PropTypes.bool,
    qty: PropTypes.number,
    product: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  }).isRequired,
};
export default Operation;
