import styled from 'styled-components'

export const Label = styled('label')`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  span {
    font-size: 17px;
    font-weight: 600;
  }
`;

export const Switch = styled('div')`
  position: relative;
  width: 50px;
  height: 26px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 21px;
    height: 21px;
    border-radius: 35px;
    top: 50%;
    left: 3px;
    background: white;
    transform: translate(0, -50%);
  }
`;

export const Input = styled('input')`
  display: none;

  &:checked + ${Switch} {
    background: #263238;

    &:before {
      transform: translate(23px, -50%);
    }
  }
`;
