import React from "react";
import ReactDOM from "react-dom/client";
// Styles
import "@agile-factory/styles-icons";
import "bulma/css/bulma.css";
import "react-simple-keyboard/build/css/index.css";
import "react-screen-keyboard/src/Keyboard.css";
import "./css/fonts.css";
import "./css/circle.css";
import "./css/helpers.css";
import "./css/loader.css";
import "./css/style.css";
import "./css/popup-buttons.css";
import "react-datepicker/dist/react-datepicker.css";
// Routing
import Routes from "./routes";
// Localization

import { unregister } from "./registerServiceWorker";
import { withClearCache } from "./hocs";

const App = withClearCache(Routes);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
unregister();
