import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import Clock from "../Clock";
import { SmallButton } from "../Button";
import themes from "../../libs/themes";
import SessionInfo from "../SessionInfo";
import HmiInfo from "../HmiInfo";
import UserInfo from "../UserInfo";
import HeaderMenu from "../HeaderMenu";
import { useHistory } from "react-router-dom";
import ToggleSwitch from "../Switch";

/**
 * Header
 *
 * @param children
 * @param history
 * @param hasHmiInfo
 * @param hasSessionInfo
 * @param hasUserInfo
 * @param hasClock
 * @returns {*}
 * @constructor
 */
function Header({
                  children,
                  hasHmiInfo,
                  hasSessionInfo = true,
                  hasUserInfo = false,
                  hasClock = true,
                  hasMenu = false,
                  hasScheduler = false,
                  sortByOrdersOrScheduler = undefined,
                  onToggleSort = undefined,
                }) {
  const intl = useIntl();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="columns">
      {/* HMI INFO */}
      {hasHmiInfo && (
        <div className="column is-4 is-paddingless-top is-paddingless-bottom">
          <HmiInfo />
        </div>
      )}
      {/* USER INFO */}
      {hasUserInfo && (
        <div className="column is-4 is-paddingless-top is-paddingless-bottom">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/worker/dashboard`)}
          >
            <UserInfo />
          </div>
        </div>
      )}
      {/* SESSION INFO */}
      {hasSessionInfo && (
        <div className="column is-4 is-paddingless-top is-paddingless-bottom">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/worker/dashboard`)}
          >
            <SessionInfo />
          </div>
        </div>
      )}
      {/* CLOCK */}
      {hasClock && (
        <div className="column is-4 has-text-centered">
          <Clock />
        </div>
      )}
      {hasMenu && (
        <div className="column is-1 is-offset-3">
          <SmallButton
            isFullWidth
            theme={themes.default.buttons}
            data-cy="application-button-menu"
            onClick={() => setShowMenu(true)}
          >
            <FormattedMessage id="app.shared.menu" defaultMessage="Menu" />
          </SmallButton>
        </div>
      )}
      {showMenu && <HeaderMenu onClose={() => setShowMenu(false)} />}
      {hasScheduler &&
        <div className="column is-4 is-flex is-justify-content-flex-end">
          <ToggleSwitch
            value={sortByOrdersOrScheduler}
            onChange={onToggleSort}
            off={intl.formatMessage({
              id: "app.components.header.switch.off",
              defaultMessage: "Sort by Orders",
            })}
            on={intl.formatMessage({
              id: "app.components.header.switch.on",
              defaultMessage: "by Scheduler",
            })}
          />
        </div>
      }
      {children}
    </div>
  );
}

Header.propTypes = {
  hasMenu: PropTypes.bool,
  hasSessionInfo: PropTypes.bool,
  hasClock: PropTypes.bool,
  hasUserInfo: PropTypes.bool,
  children: PropTypes.element,
};
export default Header;
